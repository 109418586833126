import { getParsedTrackingParams } from 'marketplace-ui-common/utils/trackingParams';
import events from '../../events.yaml';
const getSessionUrlParams = () => {
  const trackingParams = getParsedTrackingParams();
  return Object.keys(trackingParams).reduce((final, key) => {
    const value = trackingParams[key];
    final.push(`${key}=${value}`);
    return final;
  }, []);
};
export const getTrackerConfig = ({
  inAppOrPublic = 'in-app'
}) => ({
  events,
  properties: {
    namespace: 'marketplace',
    inAppOrPublic,
    sessionUrlParams: getSessionUrlParams()
  },
  lastKnownEventProperties: ['screen']
});