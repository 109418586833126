import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import quickFetch from 'quick-fetch';
import * as t from 'ts-schema';
const publicAuthSchema = t.object({
  email: t.string
});
export async function getPublicAuth() {
  const earlyRequest = quickFetch.getRequestStateByName('public-auth-check');
  const fetchPublicAuth = () => noAuthApiClient.get('/cookie-echo/v1/user');
  let response;
  if (earlyRequest) {
    try {
      response = await new Promise((resolve, reject) => {
        earlyRequest.whenFinished(resp => {
          resolve(resp);
          quickFetch.removeEarlyRequest('public-auth-check');
        });
        earlyRequest.onError(() => {
          fetchPublicAuth().then(resp => resolve(resp)).catch(error => reject(error));
          quickFetch.removeEarlyRequest('public-auth-check');
        });
      });
    } catch (_unused) {
      return null;
    }
  } else {
    try {
      response = await fetchPublicAuth();
    } catch (_unused2) {
      return null;
    }
  }
  try {
    return t.assert(publicAuthSchema, response);
  } catch (_unused3) {
    return null;
  }
}