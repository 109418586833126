module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "sessionUrlParams": "array"
    },
    "namespace": "marketplace",
    "version": "1"
  }
};