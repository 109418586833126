import { useCallback, useState } from 'react';
export const useChecked = ({
  checked,
  defaultChecked = false,
  onChange
}) => {
  const [checkedState, setCheckedState] = useState(defaultChecked);
  const handleChange = useCallback(evt => {
    setCheckedState(evt.target.checked);
    if (onChange) onChange(evt);
  }, [onChange]);
  return {
    checked: checked !== undefined ? checked : checkedState,
    onChange: handleChange
  };
};