import { inPx } from './utils';
export const GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_COLLAPSED_WIDTH = '64px';
export const GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_EXPANDED_WIDTH = '236px';
export const GLOBAL_NAVIGATION_SIDEBAR_WIDTH = `var(--global-nav-vertical-nav-width, ${GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_COLLAPSED_WIDTH})`;
export const GLOBAL_NAVIGATION_TOOLBAR_DEFAULT_HEIGHT = '44px';
export const GLOBAL_NAVIGATION_TOOLBAR_HEIGHT = `var(--global-nav-height, ${GLOBAL_NAVIGATION_TOOLBAR_DEFAULT_HEIGHT})`;
export const BREAKPOINT_SMALL = '544px';
export const BREAKPOINT_MEDIUM = '768px';
export const BREAKPOINT_LARGE = '992px';
export const BREAKPOINT_EXTRA_LARGE = '1200px';

// ------------------------------ TODO: Deprecate usages of variables below this line -------------------------------------------------

/**
 * @deprecated Please use GLOBAL_NAVIGATION_TOOLBAR_HEIGHT in CSS
 */
export const GLOBAL_TOOLBAR_HEIGHT_NUMBER = 32;

/**
 * @deprecated Please use GLOBAL_NAVIGATION_TOOLBAR_HEIGHT in CSS
 */
export const GLOBAL_TOOLBAR_HEIGHT = inPx(GLOBAL_TOOLBAR_HEIGHT_NUMBER);

/**
 * @deprecated Please migrate to GLOBAL_NAVIGATION_TOOLBAR_HEIGHT in CSS this value can update with the App Lifecycle
 */
export const PRIMARY_NAV_HEIGHT = '54px';

/**
 * @deprecated Please use GLOBAL_NAVIGATION_TOOLBAR_HEIGHT in CSS this value can update with the App Lifecycle
 */
export const PRIMARY_NAV_HEIGHT_NUMBER = parseInt(PRIMARY_NAV_HEIGHT, 10);

/**
 * @deprecated Please use GLOBAL_NAVIGATION_TOOLBAR_HEIGHT in CSS this value can change over the App's lifeclycle
 */
export const SIDEBAR_WIDTH = GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_EXPANDED_WIDTH;

/**
 * @deprecated Please use GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_COLLAPSED_WIDTH in CSS this value can change over the App's lifeclycle
 */
export const VERTICAL_NAV_WIDTH_COLLAPSED = parseInt(GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_COLLAPSED_WIDTH, 10);

/**
 * @deprecated Please use GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_COLLAPSED_WIDTH in CSS this value can change over the App's lifeclycle
 */
export const SIDEBAR_WIDTH_NUMBER = parseInt(GLOBAL_NAVIGATION_SIDEBAR_DEFAULT_EXPANDED_WIDTH, 10);