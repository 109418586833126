import { getFullUrl } from 'hubspot-url-utils';
import { na1 } from 'hubspot-url-utils/hublets';
import { PUBLIC_LANGUAGES } from 'marketplace-ui-common/constants/Languages';
import PortalIdParser from 'PortalIdParser';
export function getRootUrl() {
  return `/marketplace/${PortalIdParser.get()}`;
}
export function getShortlinkUrl() {
  return '/l/marketplace';
}
export function isPublicLang(lang) {
  return PUBLIC_LANGUAGES.includes(lang);
}
export function getPublicRootUrl(lang) {
  const publicLang = isPublicLang(lang) ? lang : 'en';
  return `${publicLang !== 'en' ? `/${publicLang}` : ''}/marketplace`;
}
export const getTemplateMarketplaceUrl = (appContext, lang) => {
  const baseUrl = getFullUrl(appContext === 'app' ? 'app' : 'ecosystem', {
    hubletOverride: na1
  });
  if (appContext === 'app') {
    return `${baseUrl}/ecosystem/${PortalIdParser.get()}/marketplace/templates`;
  }
  return `${baseUrl}${getPublicRootUrl(lang)}/templates`;
};
export const getSolutionsDirectoryUrl = (appContext, lang) => {
  const baseUrl = getFullUrl(appContext === 'app' ? 'app' : 'ecosystem', {
    hubletOverride: na1
  });
  if (appContext === 'app') {
    return `${baseUrl}/ecosystem/${PortalIdParser.get()}/marketplace/solutions`;
  }
  return `${baseUrl}${getPublicRootUrl(lang)}/solutions`;
};