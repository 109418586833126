import * as ScreenSizes from './ScreenSizes';
export const MAIN_SECTION_MAX_WIDTH = 1280;
export const MAIN_SECTION_PADDING_X = 48;
export const MAIN_SECTION_PADDING_Y = 40;
export const SCREEN_SIZE_ORDER = [ScreenSizes.XS, ScreenSizes.SM, ScreenSizes.MD, ScreenSizes.LG, ScreenSizes.XL];
export const GRID_BREAKPOINT_SMALL = '544px';
export const GRID_BREAKPOINT_SMALL_INT = 544;
export const GRID_BREAKPOINT_MEDIUM = '768px';
export const GRID_BREAKPOINT_MEDIUM_INT = 768;
export const GRID_BREAKPOINT_LARGE = '992px';
export const GRID_BREAKPOINT_LARGE_INT = 992;
export const GRID_BREAKPOINT_EXTRA_LARGE = '1200px';
export const GRID_BREAKPOINT_EXTRA_LARGE_INT = 1200;