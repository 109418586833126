import enviro from 'enviro';
const PACKAGE_NAME = 'marketplace-storefront-ui';
export function debug() {
  return String(enviro.debug(PACKAGE_NAME)) === 'true';
}
export function debugLog(...args) {
  if (debug()) {
    console.log(...args);
  }
}
export function deployed() {
  return enviro.deployed(PACKAGE_NAME);
}