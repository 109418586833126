module.exports = {
  "/": {
    "name": "index",
    "success": {
      "index page loaded": [
        "INDEX_LOAD"
      ]
    }
  },
  "/apps": {
    "name": "apps",
    "success": {
      "apps page loaded": [
        "APPS_LOAD"
      ]
    }
  },
  "/agents": {
    "name": "agents",
    "success": {
      "agents page loaded": [
        "AGENTS_LOAD"
      ]
    }
  },
  "/featured": {
    "name": "featured",
    "success": {
      "featured page loaded": [
        "FEATURED_LOAD"
      ]
    }
  },
  "/featured/:collectionSlug": {
    "name": "featuredCollection",
    "success": {
      "featured collection page loaded": [
        "FEATURED_COLLECTION_LOAD"
      ]
    }
  },
  "/explore": {
    "name": "explore",
    "success": {
      "explore page loaded": [
        "EXPLORE_LOAD"
      ]
    }
  },
  "/explore/page/:page": {
    "name": "explorePage",
    "success": {
      "explore page loaded": [
        "EXPLORE_LOAD"
      ]
    }
  }
};