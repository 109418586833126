import { useTheme } from 'styled-components';
function getStorefrontHeaderColorScheme(color) {
  switch (color) {
    case 'orange':
      return {
        backgroundColor: 'fill-accent-orange-default',
        gradientStartColor: 'fill-accent-orange-default',
        gradientEndColor: 'fill-accent-red-default'
      };
    case 'purple':
      return {
        backgroundColor: 'fill-accent-purple-default',
        gradientStartColor: 'fill-accent-purple-default',
        gradientEndColor: 'fill-accent-magenta-pressed'
      };
    case 'teal':
      return {
        backgroundColor: 'fill-accent-blue-pressed',
        gradientStartColor: 'fill-accent-blue-pressed',
        gradientEndColor: 'fill-accent-teal-default'
      };
    case 'blue':
    default:
      return {
        backgroundColor: 'fill-accent-blue-pressed',
        gradientStartColor: 'fill-accent-blue-pressed',
        gradientEndColor: 'fill-accent-purple-default'
      };
  }
}
export function useStorefrontHeaderBackgroundStyles({
  backgroundImage,
  color,
  gradientAngle = -303
}) {
  const theme = useTheme();
  const {
    backgroundColor,
    gradientStartColor,
    gradientEndColor
  } = getStorefrontHeaderColorScheme(color);
  const gradient = `linear-gradient(${gradientAngle}deg, ${theme.color[gradientStartColor]}, ${theme.color[gradientEndColor]})`;
  return {
    backgroundColor: theme.color[backgroundColor],
    backgroundImage: backgroundImage ? [backgroundImage, gradient].join(', ') : gradient
  };
}