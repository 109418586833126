import { useHistory, useLocation } from 'react-router-dom';
export function useSearchParams() {
  const location = useLocation();
  const history = useHistory();
  return {
    get: (key, {
      allowedValues
    } = {}) => {
      const params = new URLSearchParams(location.search);
      const value = params.get(key);

      // return empty string if outside of allowed values
      if (allowedValues && !allowedValues.includes(value !== null && value !== void 0 ? value : '')) return null;
      return value;
    },
    set: (key, value, {
      defaultValue
    } = {}) => {
      const params = new URLSearchParams(location.search);
      if (value === defaultValue) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
      history.push(`${location.pathname}?${params.toString()}`);
    }
  };
}