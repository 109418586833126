import { useMemo } from 'react';
import { GRID_BREAKPOINT_EXTRA_LARGE_INT, GRID_BREAKPOINT_LARGE_INT, GRID_BREAKPOINT_MEDIUM_INT, GRID_BREAKPOINT_SMALL_INT } from '../layout/constants';
export function useBreakpoint(width) {
  return useMemo(() => {
    if (width < GRID_BREAKPOINT_SMALL_INT) {
      return 'xs';
    } else if (width < GRID_BREAKPOINT_MEDIUM_INT) {
      return 'sm';
    } else if (width < GRID_BREAKPOINT_LARGE_INT) {
      return 'md';
    } else if (width < GRID_BREAKPOINT_EXTRA_LARGE_INT) {
      return 'lg';
    } else {
      return 'xl';
    }
  }, [width]);
}