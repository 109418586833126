const imageUrls = ['https://cdn2.hubspot.net/hubfs/521324/App-Icon-1200x1200-gmail.png', 'https://hubspot-integrators-prod.s3.amazonaws.com/listing/35224.png', 'https://cdn2.hubspot.net/hubfs/521324/App-Icon-1200x1200-wordpress.png', 'https://cdn2.hubspot.net/hubfs/521324/App-Icon-1200x1200-outlook.png', 'https://521324.fs1.hubspotusercontent-na1.net/hubfs/521324/__hs-marketplace__/150px-1.png', 'https://cdn2.hubspot.net/hubfs/521324/FBlogo%20copy.png', 'https://hubspot-integrators-prod.s3.amazonaws.com/listing/184945.png', 'https://cdn2.hubspot.net/hubfs/521324/logo.png', 'https://f.hubspotusercontent20.net/hubfs/521324/__hs-marketplace__/Zoom%20-%20Camera-1.png', 'https://f.hubspotusercontent20.net/hubfs/521324/blob-Nov-05-2020-01-21-38-81-PM.png', 'https://f.hubspotusercontent20.net/hubfs/521324/__hs-marketplace__/Slack_Mark_Web-1.png', 'https://cdn2.hubspot.net/hubfs/521324/App-Icon-1200x1200-linkedin-1.png'];
function randomImageUrl() {
  return imageUrls[Math.floor(Math.random() * imageUrls.length)];
}
const LOREM_IPSUM = `lorem ipsum dolor sit amet consectetur adipiscing elit
sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad
minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
commodo consequat duis aute irure dolor in reprehenderit in voluptate velit
esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat
non proident sunt in culpa qui officia deserunt mollit anim id est laborum`.split(' ');
function loremIpsum(meanLength) {
  // Use normal distribution: Box-Muller transform
  const standardDeviation = meanLength / 4; // reasonable deviation
  const u1 = Math.random();
  const u2 = Math.random();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);

  // Calculate words using normal distribution, ensure at least 1 word
  const words = Math.max(1, Math.round(meanLength + z0 * standardDeviation));
  let result = '';
  for (let i = 0; i < words; i++) {
    result += ` ${LOREM_IPSUM[Math.floor(Math.random() * LOREM_IPSUM.length)]}`;
  }
  return result.charAt(1).toUpperCase() + result.slice(2);
}
export function createMockListing() {
  return {
    name: loremIpsum(3),
    providerName: loremIpsum(3),
    description: loremIpsum(10),
    listingHref: `https://www.google.com`,
    imageSrcSmall: randomImageUrl()
  };
}
export function mockHomepageData() {
  return {
    sections: [{
      type: 'SLIDER',
      title: loremIpsum(7),
      listings: Array.from({
        length: 10
      }, createMockListing)
    }, {
      type: 'SLIDER',
      title: loremIpsum(7),
      listings: Array.from({
        length: 10
      }, createMockListing)
    }, {
      type: 'COLLECTION',
      title: loremIpsum(7),
      description: loremIpsum(15),
      listings: Array.from({
        length: 6
      }, createMockListing),
      imageUrl: 'https://git.hubteam.com/storage/user/5145/files/bf3ab6a2-1df6-4b7f-8494-5cd10479007b',
      collectionSlug: 'mock-collection-slug'
    }, {
      type: 'SLIDER',
      title: loremIpsum(7),
      listings: Array.from({
        length: 10
      }, createMockListing)
    }]
  };
}